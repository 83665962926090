import React, { Component } from 'react';
import banneryellow from '../img/shape/BG-1.png'
import DirecteurImage from '../img/Directeur/Directeur-auto.jpg'
import Qoute from '../img/icon/icone-doyen.png'


class Directeur extends Component {

    render() {

        return (
        <section className="why__area pt-50 mb-40">
            <div className="container">
               <div className="row align-items-center justify-content-between">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8">
                     <div className="why__content mt-40">
                        <div className="section__title-wrapper pr-0 mb-30">
                           <h2 className="section__title">MOT <span className="yellow-bg yellow-bg-big"> DU DOYEN<img src={banneryellow} alt="img not found" /></span></h2>
                              <img src={Qoute} />
                           <p className='pt-15'>
                           En peu de temps, l’Ecole de Droit (ED) a réussi à attirer de nombreux étudiants à la recherche d’une voie conduisant à un métier. Les métiers du Droit sont, en effet, multiples, et ce pour une raison très simple : il n’y a guère d’activité humaine, politique, administrative, économique, sociale, culturelle, nationale ou internationale, qui puisse fonctionner sans un encadrement juridique...
                           </p>
                           <p>
                           M.Amine Mzouri
                           <span className='d-block'>Doyen de l’Ecole de Droit de Rabat</span>
                           </p>
                        </div>
                        <div className="why__btn">
                           <a href="/contact"><a className="g-btn e-btn-3 mr-30">Rejoignez-nous</a></a>
                        </div>
                     </div>
                  </div>
                  <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">
                     <div className="why__thumb">
                        <img src={DirecteurImage} alt="img not found" className='w-100' />
                     </div>
                  </div>
               </div>
            </div>
         </section>
        );
    }
}

export default Directeur;