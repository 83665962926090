import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import image1 from '../img/etude/Students-1.jpg';
import image2 from '../img/etude/Students-2.jpg';
import banneryellow from '../img/shape/BG-1.png'

class Service extends Component {

    render() {

        return (
        <section className="about__area pt-70 pb-70">
            <div className="container">
               <div className="row">
                  <div className="col-xxl-5 col-xl-5 col-lg-5 d-flex justify-content-center align-items-center">
                     <div className="about__thumb-wrapper">
                        <div className="about__thumb ml-100">
                           <img src={image1} alt="img not found" />
                        </div>
                     </div>
                  </div>
                  <div className="col-xxl-7 col-xl-7 col-lg-7">
                     <div className="about__content pl-70 pr-60 pt-25">
                        <div className="section__title-wrapper mb-25">
                           <h2 className="section__title">
                              <span className="yellow-bg-big">
                              Organisation  <img src={banneryellow} alt="img not found" />
                           </span> des études
                              </h2>
                        </div>
                        <div className="about__list mb-35">
                           <ul className='serivce'>
                              <li>
                              La licence en droit des affaires est ouverte à tous types de bacheliers et vise à former des juristes spécialisés dans le traitement des dossiers commerciaux et financiers.
                              </li>
                              <li> 
                              Les juristes que nous formons pourront répondre aux besoins pressants des milieux d’affaires en juristes spécialisés grâce à la maîtrise d’une véritable culture juridique et technique de rédaction, en particulier des contrats , des statuts et des pactes.                              </li>
                              <li>
                              L’étudiant acquiert des capacités de prévention des litiges, de veille juridique, de gestion des contentieux, renforcées par des stages en entreprise
                              </li>
                              
                           </ul>
                        </div>
                        <a className="e-btn">Voir Plus</a>
                     </div>
                  </div>
               </div>
            </div>
         </section>
        );
    }
}

export default Service;